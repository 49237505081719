export const filterArr = [
	[
		{
			label: '姓名',
			type: 'input',
			attr: 'name',
			placeholder: '请输入姓名',
			column: 6,
			labelWidth: '96px',
		},
		{
			label: '手机号',
			type: 'input',
			attr: 'mobile',
			placeholder: '请输入手机号',
			column: 6,
			labelWidth: '96px',
		},
		{
			label: '注册时间',
			type: 'dateRange',
			valueFormat:"YYYY-MM-DD",
			attr: 'date',
			placeholder: '请选择',
			column: 6,
			labelWidth: '96px',
		},
		{ type: 'button', column: 6 },
	],
]
export const tableColumn = [
	// {
	// 	label: '索引',
	// 	prop: 'name',
	// 	width: '80px',
	// 	fixed: true,
	// },
	{
		label: '导入批次',
		prop: 'id',
		width: '',
	},
	{
		label: '导入结果',
		prop: 'result',
		width: '',
	},
	{
		label: '操作人',
		prop: 'username',
		width: '',
	},
	{
		label: '操作时间',
		prop: 'createTime',
		width: '',
	},
	// {
	// 	label: '操作',
	// 	prop: 'failRecord',
	// 	width: '120px',
	// },
]
