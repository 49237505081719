<template>
  <div class="import-customer">
    <h3>1.下载模版按照需求填写数据；2.选择导入位置；3.上传EXCEL文件，并导入数据</h3>
    <div class="filter-block">
      <el-button
        size="small"
        icon="el-icon-download"
        @click="downloadT"
      >下载模版</el-button>
      <el-select
        class="select"
        v-model="importTo"
        size="small"
      >
        <el-option
          :label="option.label"
          :value="option.val"
          v-for="(option, index) in options"
          :key="index"
        >
        </el-option>
      </el-select>
      <el-upload
        class="upload-demo"
        action="/admin/api/v1/customers/import"
        :headers="{
            Authorization: token
        }"
        :on-change="handleChange"
        :data="{to: importTo}"
      >
        <el-button
          type="primary"
          size="small"
          icon="el-icon-upload"
        >上传文件</el-button>
      </el-upload>

    </div>
  </div>
  <ImportCustomerTable
    :tableColumn="tableColumn"
    :tableData="tableData"
    :page="pagination.page"
    :size="pagination.size"
  />
  <ImportPagination
    :total="total"
    @currentChange="currentChange"
    @sizeChange="sizeChange"
  />
</template>

<script>
import ImportCustomerTable from "@/components/table";
import ImportPagination from "@/components/pagination";
import { computed, onMounted, reactive, ref } from "vue";
import { tableColumn } from "./index.js";
import { useStore } from "vuex";
import { ElButton, ElOption, ElSelect, ElUpload } from "element-plus";
import base from "@/api/base";

export default {
  name: "ImportCustomer",
  props: [],
  components: {
    ImportCustomerTable,
    [ElUpload.name]: ElUpload,
    [ElButton.name]: ElButton,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    ImportPagination,
  },

  setup() {
    const { dispatch, state } = useStore();
    const importTo = ref(null);
    const options = reactive([
      {
        label: "公海",
        val: 3,
      },
      {
        label: "重要客户",
        val: 2,
      },
    ]);
    onMounted(() => {
      getList();
    });
    const pagination = reactive({
      page: 1,
      size: 10,
    });
    const tableData = computed(
      () => (state.importRecords && state.importRecords.records) || []
    );
    const total = computed(
      () => state.importRecords && state.importRecords.total
    );
    const getList = () => {
      dispatch("importRecords", {
        ...pagination,
      });
    };

    const downloadT = async () => {
      window.location.href = base.downloadTemplate;
    };

    const token = ref(`Bearer ${localStorage.getItem("token")}`);
    const currentChange = (page) => {
      pagination.page = page;
      getList();
    };
    const sizeChange = (size) => {
      pagination.size = size;
      getList();
    };

    return {
      tableData,
      tableColumn,
      importTo,
      options,
      downloadT,
      token,
      pagination,
      total,
      currentChange,
      sizeChange,
    };
  },
};
</script>

<style lang="scss" scoped>
.import-customer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  .filter-block {
    display: flex;
  }
  .select {
    margin: 0 20px;
  }
}
</style>